/******************************************************************************
* FILENAME:
*   sub-header.styles.scss

* DESCRIPTION:
*   

* NOTES:
*   - 

* (c) Copyright Ashley Thomas
* Usage Rights: Not for public use or redistribution.

******************************************************************************/

.sub-header-container {
  display: flex;
  width: 100vw;
  align-items: center;
  gap: 15px;
  padding-left: 10px;
  padding-right: 73px;
  box-sizing: border-box;
  overflow-x: scroll;
  position: fixed;
  top: 110px;
  z-index: 5000;
  user-select: none;

  /* width */
  &::-webkit-scrollbar {
    height: 5px;
    cursor: pointer !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgb(71, 71, 86);
    cursor: pointer !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(90, 90, 108);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(108, 108, 130);
    cursor: pointer !important;
  }

  button {
    color: white;
    position: fixed;
    right: -9px;
    height: 75px;
    text-transform: uppercase;
    z-index: 10000;
    background-color: #1a1a1a;
  }
}

/* END of document ***********************************************************/
