:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --toastify-color-progress-light: #5856d650;

  --toastify-toast-background: #555555!important;
  --toastify-color-light: #020202!important;
  --toastify-text-color-light: whitesmoke;
  --toastify-toast-width: 300px;
  --toastify-color-dark: #121212!important;
  --font-monospace: 'Source Code Pro', monospace;
  --font-header: 'Bungee Hairline', sans-serif;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  background-color: #121216;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: var(--font-monospace);
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:hover {
  border-color: #ddd;
}
button:focus,
button:focus-visible {
  outline: none;
}

h1,h2,h3,h4,h5,h6 {
  background-color: #0000;
}

* {
  box-sizing: border-box;
}

