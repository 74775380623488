/******************************************************************************
* FILENAME:
*   display-pane.styles.scss

* DESCRIPTION:
*   

* NOTES:
*   - 

* Usage Rights: Not for public use or redistribution.

******************************************************************************/

.display-pane {
    display: flex;
    height: 100vh;

    .primary-view {
        position: relative;
        width: 100vw;
        height: calc(100vh - 161px);
        top: 161px;
    }
}

/* END of document ***********************************************************/