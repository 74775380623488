/******************************************************************************
* FILENAME:
*   sign-in-up.component.tsx

* DESCRIPTION:
*   

* NOTES:
*   - 

* Usage Rights: Not for public use or redistribution.

******************************************************************************/

.sign-in {
  width: 400px;
  display: inline-flex;
  flex-direction: column;

  span {
    text-align: center;

    font-family: var(--font-monospace);
    font-size: 14px;
    font-weight: 700;
    line-height: 18.2px;
  }

  h2 {
    text-align: center;
    font-family: var(--font-monospace);
    font-size: 30px;
    font-weight: 800;
    line-height: 39px;
    background-color: #0000;
  }

  .title {
    margin: 10px 0px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  button {
    font-family: var(--font-monospace);
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    width: 286px;
    height: 46px;
    color: white;
    border-radius: 12px;
    background-color: #1e1e1e;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    margin: 20px 0;
    padding: 0;
  }
}

/* END of document ***********************************************************/
