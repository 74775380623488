/******************************************************************************
* FILENAME:
*   header.styles.scss

* DESCRIPTION:
*   

* NOTES:
*   - 

* (c) Copyright Ashley Thomas
* Usage Rights: Not for public use or redistribution.

******************************************************************************/

.header {
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    position: fixed;
    top: 0;
    z-index: 3000;
    user-select: none;
    color: black;
    border-bottom: solid 5px rgb(71, 71, 86) !important;
    font-family: var(--font-header);

    h1,
    h2 {
        border: none;
        color: white;
        margin: 0;

        &.tooltip {
            position: relative;
        }

        &.tooltip::before {
            font-size: 14px !important;
            font-weight: 400 !important;
            content: attr(data-text);
            position: absolute;
            top: 150%;
            transform: translateX(-50%);
            left: 50%;
            margin-left: 15px;
            width: 150px;
            padding: 10px 6px;
            border-radius: 10px;
            background: #000;
            color: #fff;
            text-align: center;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.25s ease-in-out;
        }

        &.tooltip:hover:before {
            opacity: 1;
        }
    }

    .title-control-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 16px;
        width: auto;
        height: 40px;
        flex: none;
        order: 0;
        flex-grow: 0;

        h1 {

            span {
                &.animate-X {
                    animation: spinAroundX;
                    animation-duration: 0.5s;
                }

                &.animate-Y {
                    animation: spinAroundY;
                    animation-duration: 0.5s;
                }

                &.animate-Z {
                    animation: spinAroundZ;
                    animation-duration: 0.5s;
                }
            }
        }
    }

    @keyframes spinAroundX {
        100% {
            transform: rotateX(360deg)
        }
    }

    @keyframes spinAroundY {
        100% {
            transform: rotateY(360deg)
        }
    }

    @keyframes spinAroundZ {
        100% {
            transform: rotateZ(360deg)
        }
    }

    .sign-up-btn {
        font-family: proxima-nova, sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        width: 190px;
        height: 38px;
        color: white;
        border-radius: 12px;
        background-color: #222;
        cursor: pointer;
        left: 25px;
        position: relative;
        padding: 0;
        font-family: var(--font-monospace)
    }

    .sign-modal {
        font-family: proxima-nova, sans-serif;
        font-size: 30px;
        font-weight: 800;
        box-sizing: content-box;
        display: none;
        position: absolute;
        width: 444px;
        height: 205px;
        top: calc(100vh / 2);
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 30px;
        background-color: #000;
        pointer-events: all;
        z-index: 1000000001;
        border-radius: 24px;
        outline: 2px solid #ddd;
    }

    .user-photo {
        position: relative;
        border: none;
        height: 55px;
        width: 50px;
        background-color: #00000000;
        line-height: 21.6px;
        left: 15px;
        opacity: .6;
        transition: opacity .1s ease-in-out;
    }

    .user-photo:focus {
        outline: none;
    }

    .user-photo:hover {
        opacity: 1;
    }

}

/* END of document ***********************************************************/