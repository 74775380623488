/******************************************************************************
* FILENAME:
*   entry.styles.scss

* DESCRIPTION:
*   

* NOTES:
*   - 

* Usage Rights: Not for public use or redistribution.

******************************************************************************/

.entry-container {
    position: relative;
    width: 270px;
    height: auto;
    min-height: 61px;
    min-width: 120px;
    font-size: 12px;
    margin: 10px 0;
    font-family: var(--font-monospace);

    .top-right-corner {
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: 1;
        border-top: 4px solid #4c4c84;
        border-right: 4px solid #4c4c84;
        width: 30px;
        height: 30px;
    }

    .is-open-arrow {
        position: absolute;
        right: -5px;
        height: 10px;
        width: 10px;
        transform: rotate(45deg) translateY(-50%);
        background-color: #4c4c84;
        top: 50%;
    }

    .inner-wrapper {
        width: 270px;
        height: auto;
        min-height: 61px;
        padding: 4px 10px 5px 10px;
        min-width: 120px;
        z-index: 2;
        background-color: rgb(45, 45, 50);

        h4 {
            margin: 0 0 3px 0 !important;
            padding: 0;
            line-height: 1.3;
            font-weight: 600 !important;
            z-index: 3;
        }

        p {
            margin: 0 20px 0 0;
            line-height: 1.15;
            font-style: italic;
            font-size: 13px;
            z-index: 3;
        }

        button {
            position: absolute;
            height: 18px;
            width: 18px;
            padding: 0px 0 1px 0;
            font-size: 12px;
            background-color: grey;
            right: 4px;
            z-index: 4;
            zoom: 74%;
            transition: height 0.1s ease-in-out, width 0.1s ease-in-out;
        }

        button:hover {
            height: 24px;
            width: 24px;
            border: none;
        }

        button:focus {
            outline: none !important;
        }
    }
}

/* END of document ***********************************************************/