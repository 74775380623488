/******************************************************************************
* FILENAME:
*   sign-in-up-modal.styles.scss

* DESCRIPTION:
*   

* NOTES:
*   - 

* (c) Copyright Ashley Thomas
* Usage Rights: Not for public use or redistribution.

******************************************************************************/

.sign-in-and-sign-up {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    background-color: #000;
    padding: 10px 10px;
    z-index: 2147483647;
    color: white;

    button {
        padding: 6px 14px;
    }

}

/* END of document ***********************************************************/