/******************************************************************************
* FILENAME:
*   add-pane.styles.scss

* DESCRIPTION:
*   

* NOTES:
*   - 

* (c) Copyright Ashley Thomas
* Usage Rights: Not for public use or redistribution.

******************************************************************************/

.add-pane-container {
    width: 600px;
    min-height: 400px;
    height: auto;
    border-radius: 12px;
    background-color: #333;
    border: 3px solid #ddd;
    top: 50vh;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 10px 30px;
    background-color: #222;
    position: absolute;
    z-index: 1000;

    textarea {
        display: block;
        width: 100%;
        min-height: 100px;
        resize: vertical;
    }

    span {
        display: block;
    }
}

/* END of document ***********************************************************/